<template>

    <div class="mb-4">

        <div class="flex items-center justify-between" :class="block?'':'cursor-pointer'">

            <p @click.prevent="descToggle()" class="flex items-center leading-none pr-2 cursor-pointer font-bold text-lg"><Plus :active="active" :theme="theme" />{{ $t(`lightbox.${tit}`) }}</p>

            <toggleConf
                :theme="theme"
                :accent="accent"
                :text="text"
                :value="value"
                :block="block"
                @toggleService="toggleService"
                :name="label"
                :label="label"
            />

        </div>

        <div class="transition-all px-2 mt-2 pl-8 flex justify-between items-start rounded-sm" :class="descClasses">

            <p>{{ $t(`lightbox.${desc}`) }}</p>

        </div>

    </div>

</template>

<script>

    import { EventBus } from '@/event-bus.js'

    import Plus from '@/components/Plus'
    import toggleConf from '@/components/toggleConf'

    export default {

        props: ['tit','desc','theme','accent','text','label','value','block'],

        data() {

            return {

                descView: false,
                active: false

            }

        },

        components: {

            Plus,
            toggleConf

        },

        computed: {

            descClasses: function() {

                let classes

                if( this.theme == 'dark' ) classes = 'bg-gray-300 text-black'
                if( this.theme == 'light' ) classes = 'text-gray-black'

                if( this.descView ) {

                    classes += ' h-auto'

                } else {

                    classes += ' h-0 hidden'

                }

                return classes

            }

        },

        methods: {

            descToggle() {

                this.descView = !this.descView
                this.active = !this.active

            },

            toggleService() {

                console.log('toogle lightbox');

            },

            checkServiceActive() {

                console.log('check service active');

            }

        }

    }

</script>