<template>

    <label :for="name" class="inline-flex items-center mt-1" :class="block==true?'pointer-events-none cursor-default':'pointer-events-auto cursor-pointer'" @click.prevent="activeToggle">

        <span class="relative">

        <span class="block w-16 h-6 rounded-full shadow-2xl border border-black border-solid" :class="toogleBack"></span>

            <span
                class="absolute block w-4 h-4 mt-1 rounded-full shadow-2xl inset-y-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out cursor-pointer"
                :class="value?`right-0 mr-1 bg-black`:`left-0 ml-1 bg-${accent}-200 border border-black border-solid bg-white`"
            >

                <input :id="name" type="checkbox" class="absolute opacity-0 w-0 h-0 cursor-pointer" />

            </span>

        </span>

        <span :class="`text-${text} ml-0 hidden`">{{ label }}</span>

    </label>

</template>

<script>

    import { EventBus } from '@/event-bus.js'

    export default {

        props: ['theme','accent','name','label','text','value','block'],

        data() {

            return {

                valueRender: this.value

            }

        },

        computed: {

            toogleBack: function() {

                let classes

                if(this.theme == 'dark') classes = 'bg-white'
                if(this.theme == 'light') classes = `bg-${this.accent}-100`

                if(this.valueRender == true) classes += ` bg-white`
                if(this.valueRender == false) classes += ` bg-${this.accent}-300`

                return classes

            }

        },

        methods: {

            activeToggle() {

                this.valueRender = !this.valueRender
                EventBus.$emit('toggleService',{ name: this.name, value: this.valueRender } )

            }

        }

    }
</script>