<template>

    <div class="mr-2 fill-current">

        <svg
            xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            width="5.2916665mm"
            height="5.291667mm"
            viewBox="0 0 5.2916665 5.291667"
            version="1.1"
            id="svg8">
            <g
                id="layer1"
                transform="translate(-34.485011,-75.259574)">
                <rect
                    style="fill:#000000;stroke:#000000;stroke-width:0.05733;stroke-linecap:round;stroke-linejoin:round;stop-color:#000000"
                    id="rect833"
                    width="1.001003"
                    :class="active?'opacity-0':'opacity-100'"
                    height="5.2343369"
                    x="36.630344"
                    y="75.288239" />
                <rect
                    style="fill:#000000;stroke:#000000;stroke-width:0.0573299;stroke-linecap:round;stroke-linejoin:round;stop-color:#000000"
                    id="rect833-3"
                    width="1.001003"
                    height="5.2343369"
                    x="77.404907"
                    y="-39.748013"
                    transform="rotate(90)" />
            </g>
        </svg>

    </div>

</template>

<script>

    export default {

        props: ['active','theme'],
        
    }

</script>