module.exports = {
    theme: 'light',
    accent: 'gray',
    location: 'bottom',
    cookiesPolicyUrl: '/politica_cookies.html',
    persistentButton: false,
    services: {
        googleAnalytics: {
            label: 'Google Analytics',
            url: 'https://www.googletagmanager.com/gtag/js?id=$$code$$',
            async: true,
            code: 'G-HFW1NERQ2S',
            inlinejs: "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '$$code$$' );",
            lightboxcodetit: 'googleanalytics-tit',
            lightboxcodedesc: 'googleanalytics-desc'
        }
    }
}