<template>

    <div id="veiss-cookies">

        <div v-if="renderLightbox" class="bg-white border border-black border-solid fixed w-11/12 max-w-screen-sm max-h-screen transform top-0 left-50 -translate-x-1/2 mt-8 shadow-2xl px-4 py-4 lg-py-8 lg-px-8 overflow-y-auto cookies-lightbox font-cookies text-sm" :class="lightboxClasses">

            <h2 class="font-bold text-xl mb-2">{{ $t('lightbox.tit') }}</h2>

            <p class="mb-2">{{ $t('lightbox.ini1') }}</p>
            <p class="mb-2">{{ $t('lightbox.ini2') }}</p>
            <p class="mb-2" v-if="config.cookiesPolicyUrl.length">{{ $t('lightbox.ini3') }} <a :href="config.cookiesPolicyUrl" target="_blank" class="border-b text-black border-black border-dotted font-bold">{{ $t('mainform.policy') }}</a>.</p>

            <div class="pt-2">

                <button :class="`bg-black text-white font-bold py-4 px-8 rounded-full uppercase leading-mini text-sm mb-4`"
                    @click.prevent="allCookiesLightbox()"
                >

                    <span class="mt-1 inline-block">{{ $t('mainform.allok') }}</span>

                </button>


            </div>

            <h3 class="my-2 pb-2 mb-2 text-black not-italic uppercase text-xs border-none">{{ $t('lightbox.mandatory') }}</h3>

            <div>

                <LightboxService
                    tit="mandatory"
                    desc="mandatorydesc"
                    :theme="config.theme"
                    :accent="config.accent"
                    :block="true"
                    name="mandatory"
                    label="mandatory"
                    :value="true"
                />

            </div>

            <h3 class="my-2 pb-2 mb-2 text-black not-italic uppercase text-xs border-none">{{ $t('lightbox.ok') }}</h3>

            <div>

                <LightboxService v-for="(service,index) in config.services" :key="index"
                    :tit="service.lightboxcodetit"
                    :desc="service.lightboxcodedesc"
                    :theme="config.theme"
                    :accent="config.accent"
                    :block="false"
                    :name="service.label"
                    :label="service.label"
                    :value="checkServiceActive( service.label )"
                />

            </div>

            <div class="lg-flex justify-center items-center pb-4 pt-4">

                <button class="bg-black text-white font-bold py-4 px-8 rounded-full uppercase leading-none lg-leading-mini text-sm block lg-inline-block mb-2 lg-mb-0 order-1"
                    @click.prevent="manualConfigIn()"
                >

                    <span class="mt-1 inline-block">{{ $t('mainform.buttonok') }}</span>

                </button>

                <button class="bg-white text-black border border-black font-bold py-4 px-8 rounded-full uppercase leading-mini text-sm mr-2 order-2 lg-ml-2"
                    @click.prevent="closeLightbox()"
                >

                    <span class="mt-1 inline-block">{{ $t('mainform.close') }}</span>

                </button>

            </div>

        </div>

        <div v-if="renderPill && pillStatus">

            <span class="fixed text-sm py-1 px-2 bottom-0 left-0 z-50 block mb-2 ml-2 shadow-2xl cursor-pointer font-cookies leading-tight "
                :class="pillClasses"
                @click.prevent="openMainConf()"
            ><span class="block mt-1 font-bold">{{ $t('confpill') }}</span></span>

        </div>

        <div v-if="render" id="main-form" class="fixed w-full shadow-2xl px-2 py-2 lg-px-4 lg-py-4 font-cookies " :class="mainFormClasses">

            <div class="container mx-auto lg-flex justify-between items-center py-2 px-2 lg-px-12">

            <div :class="`text-${text} text-base sm-text-sm text-left`">{{ $t('mainform.desc1') }}, <a href="#" @click.prevent="setUpConfForm()" class="border-b border-black border-dotted text-black hover:text-primary"><strong class="font-bold">{{ $t('mainform.buttonconf') }}</strong></a> {{ $t('mainform.desc2') }} <span class="inline-block" v-if="config.cookiesPolicyUrl.length">{{ $t('mainform.desc3') }} <a :href="config.cookiesPolicyUrl" target="_blank" class="border-b border-black border-dotted font-bold text-black hover:text-primary">{{ $t('mainform.policy') }}</a></span></div>

            <div v-if="renderMain" class="flex items-center justify-center mt-2 lg-mt-2">

                <button :class="`lg-w-56 lg-ml-8 bg-black text-white font-bold py-4 px-8 rounded-full uppercase leading-mini text-sm`"
                    @click.prevent="allCookies()"
                >

                    <span class="mt-1 inline-block">{{ $t('mainform.allok') }}</span>

                </button>

            </div>

            </div>

        </div>

    </div>

</template>

<script>

    // i18n plugin
    import i18n from './i18n'

    // Import config
    import config from '../veiss-cookies.config.js'

    // Tailwind CSS
    import styles from './assets/css/index.css'

    // Event bus
    import { EventBus } from './event-bus.js'

    // Components
    import toggle from './components/toggle'
    import toggleConf from './components/toggleConf'
    import LightboxService from './components/LightboxService'

    export default {
        styles,
        config,
        i18n,
        name: 'App',

        data() {

            return {

                render: false,
                renderMain: false,
                renderConf: false,
                renderPrevious: false,
                renderPill: false,
                renderLightbox: false,
                config: config,
                noService: 'noneAllowed',
                pillStatus: config.persistentButton,
                text: config.theme == 'dark' ? 'white' : 'black',
                activeServices: [],
                refuseAll: false,
                externalBtn: null,
                mainform: {
                    allok: false,
                    allnook: false,
                    config: false
                }

            }

        },

        components: {

            toggle,
            toggleConf,
            LightboxService

        },

        computed: {

            mainFormClasses: function() {

                let classes

                if(config.theme == 'dark') classes = 'bg-black'
                if(config.theme == 'light') classes = 'bg-white border-t border-black'

                if(config.location == 'top') classes += ' top-0'
                if(config.location == 'bottom') classes += ' bottom-0'

                return classes

            },

            pillClasses:  function() {

                let classes

                if(config.theme == 'dark') classes = 'bg-black opacity-75 text-white'
                if(config.theme == 'light') classes = 'bg-white text-black border border-black border-solid'

                return classes

            },

            lightboxClasses: function() {

                let classes

                if(config.theme == 'dark') classes = 'bg-black opacity-75 text-white'
                if(config.theme == 'light') classes = 'bg-gray-100 text-black'

                return classes

            }

        },

        methods: {

            closeLightbox() {

                this.renderLightbox = !this.renderLightbox
                this.renderPill = true

            },

            toogleChange(data) {

                if( data.name == "allok" ) {
                    this.mainform.allok = data.value
                    this.mainform.allnook = false
                    this.mainform.config = false
                    this.refuseAll = false
                }

                if( data.name == "allnook" ) {
                    this.mainform.allnook = data.value
                    this.mainform.allok = false
                    this.mainform.config = false
                    this.refuseAll = true
                }

                if( data.name == "config" ) {
                    this.mainform.config = data.value
                    this.mainform.allnook = false
                    this.mainform.allok = false
                }

            },

            toggleService(data) {

                if(data.value) {

                    this.activeServices.push(data.name)

                } else {

                    let index = this.activeServices.indexOf(data.name)

                    this.activeServices.splice(index, 1)

                }

            },

            allCookies() {

                this.allCookiesIn()

            },

            allCookiesLightbox() {

                this.allCookiesIn()
                this.closeLightbox()

            },

            allCookiesOut() {

                Object.keys(config.services).forEach(s => {

                    let service = config.services[s]

                    if( this.activeServices.indexOf( service.label ) != -1 ) {

                        this.deactiveService( service )

                    }

                })

                this.setCookie( 'cookielaw' , JSON.stringify(this.activeServices) , 60 )

                this.render = false

                this.renderPill = true

            },

            allCookiesIn() {

                Object.keys(config.services).forEach(s => {

                    let service = config.services[s]

                    if( this.activeServices.indexOf( service.label ) == -1 ) {

                        this.activeService( service, true )

                    }

                })

                this.setCookie( 'cookielaw' , JSON.stringify(this.activeServices) , 60 )

                this.render = false

                this.renderPill = true

            },

            manualConfigIn() {

                // Manual config override everything
                Object.keys(config.services).forEach(s => {

                    let service = config.services[s]

                    if( this.activeServices.indexOf( service.label ) != -1 ) {

                        this.activeService( service, false )

                    } else {

                        this.deactiveService( service )

                    }

                })

                // If no service allowed, save that
                if( this.activeServices.length == 0) this.activeServices.push(this.noService)

                this.setCookie( 'cookielaw' , JSON.stringify(this.activeServices) , 60 )

                this.render = false

                this.renderConf = false

                this.renderPill = true

                this.renderLightbox = false

            },

            persistentConfigIn(services) {

                Object.keys(config.services).forEach(s => {

                    let service = config.services[s]

                    if( services.indexOf( service.label ) != -1 ) {

                        this.activeService( service, true )

                    }

                })

                // If no service allowed, save that
                if( this.activeServices.length == 0) this.activeServices.push(this.noService)

                this.render = false

                this.renderConf = false

                this.renderPill = true

            },

            deactiveService( service ) {

                let index = this.activeServices.indexOf( service.label )

                if( index != -1 ) this.activeServices.splice( index, 1 )

                let eclass = this.slugify( service.label )

                let elements = document.getElementsByClassName( eclass )

                Array.prototype.forEach.call(elements, function( element ) {

                    element.remove()

                })

                if( service.inlinejs && document.getElementById( eclass + '-inline' ) ) document.getElementById( eclass + '-inline' ).remove()
                if( service.url && document.getElementById( eclass + '-external' ) ) document.getElementById( eclass + '-external' ).remove()
                if( service.noscript && document.getElementById( eclass + '-noscript' ) ) document.getElementById( eclass + '-noscript' ).remove()

            },

            activeService( service, init ) {

                // Class for all the tags in case of removal

                let eclass = this.slugify( service.label )

                // Remove the not allowed service if exists
                if( this.activeServices.indexOf( this.noService ) != -1 ) {

                    let noIndex = this.activeServices.indexOf( this.noService )
                    this.activeServices.splice( noIndex, 1 )

                }

                // Add JS to execute the service

                if( service.inlinejs ) {

                    let inline = service.inlinejs

                    let serviceJsExe = document.createElement('script')

                    serviceJsExe.setAttribute( 'id', eclass + '-inline' )

                    if( service.code ) inline = inline.replace('$$code$$', service.code)

                    let inlineScript = document.createTextNode( inline )

                    serviceJsExe.appendChild( inlineScript )

                    document.body.appendChild( serviceJsExe )

                }

                // Add service JS from external server

                if( service.url ) {

                    let url = service.url

                    if( service.code ) url = url.replace('$$code$$', service.code)

                    let serviceJsTag = document.createElement('script')

                    serviceJsTag.setAttribute( 'id', eclass + '-external' )

                    serviceJsTag.setAttribute('src', url)

                    document.body.appendChild( serviceJsTag )

                }

                // Add noscript tag if nedded

                if( service.noscript ) {

                    let serviceNoScriptHtml = service.noscript

                    let serviceNoScript = document.createElement('noscript')

                    serviceNoScript.setAttribute( 'id', eclass + '-noscript' )

                    if( service.code ) serviceNoScriptHtml = serviceNoScriptHtml.replace('$$code$$', service.code)

                    let serviceNoScriptContent = document.createTextNode( serviceNoScriptHtml )

                    serviceNoScript.appendChild( serviceNoScriptContent )

                    document.body.appendChild( serviceNoScript )

                }

                // For the cookie storage

                if(init) this.activeServices.push( service.label )

            },

            checkServiceActive( label ) {

                if( this.activeServices.indexOf( label ) == -1 ) {

                    return false

                } else {

                    return true

                }

            },

            setUpConfForm() {

                this.render = false
                this.renderLightbox = true

            },

            back() {

                this.renderConf = false
                this.renderMain = true

            },

            slugify(str) {

                str = str.replace(/^\s+|\s+$/g, '');

                // Make the string lowercase
                str = str.toLowerCase()

                // Remove accents, swap ñ for n, etc
                var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;"
                var to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------"
                for (var i=0, l=from.length ; i<l ; i++) {
                    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
                }

                // Remove invalid chars
                str = str.replace(/[^a-z0-9 -]/g, '')
                // Collapse whitespace and replace by -
                .replace(/\s+/g, '-')
                // Collapse dashes
                .replace(/-+/g, '-');

                return str

            },

            openMainConf() {

                this.renderPill = false
                this.render = true
                this.renderMain = true

            },

            /*
            *   Set cookie
            *   https://www.w3schools.com/js/js_cookies.asp
            */

            setCookie( cname , cvalue, exdays ) {

                document.cookie = cname + "=" + '' + ";expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/"

                var d = new Date()

                d.setTime(d.getTime() + (exdays*24*60*60*1000))

                var expires = "expires="+ d.toUTCString()

                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"

            },

            /*
            *   Get cookie
            *   https://www.w3schools.com/js/js_cookies.asp
            */

            getCookie( cname ) {

                var name = cname + "="

                var decodedCookie = decodeURIComponent(document.cookie)

                var ca = decodedCookie.split(';')

                for(var i = 0; i < ca.length; i++) {

                    var c = ca[i]

                    while (c.charAt(0) == ' ') {

                        c = c.substring(1)

                    }

                    if (c.indexOf(name) == 0) {

                        return c.substring(name.length, c.length)

                    }

                }

                return ""

            }

        },

        mounted() {

            this.$nextTick(() => {

                /*
                *   Open lightbox outside de Vue App
                *
                */

                this.externalBtn = document.getElementById('cookiesPop')

                if( this.externalBtn ) {

                    this.externalBtn.addEventListener('click', (e) => {

                        e.preventDefault()
                        this.setUpConfForm()

                    })

                }

            })

        },

        created() {

            /*
            *   Get the language from the html tag
            */
            let languageHtml = document.getElementsByTagName('html')[0].getAttribute('lang')

            if(languageHtml) {

                this.$i18n.locale = languageHtml

            } else {

                this.$i18n.locale = 'es'

            }

            /*
            *   Check if there is previous configuration
            */
            let previousConf = []

            if( this.getCookie( 'cookielaw' ) != '' ) {

                previousConf = JSON.parse( this.getCookie( 'cookielaw' ) )

            }

            /* console.log(previousConf) */

            if( previousConf.length > 0 ) {

                this.persistentConfigIn(  previousConf )

                /*
                *   Render the pill
                */
                this.renderPill = true

            } else {

                /*
                *   Render the full App
                */
                this.renderMain = true

                this.render = true

            }

            /**
             * Event bus
             *
             */
            EventBus.$on('toggleService', data =>  {

                // console.log('bus event', data)
                this.toggleService( data )

            })

        }

    }

</script>

<style lang="postcss" scoped>

    @import url('./assets/css/index.css');

    .cookies-lightbox {

        max-height: calc( 100vh - 4rem );
        z-index: 9999;

    }

</style>
